import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransactionService } from '../../../services/api/transactions/transaction.service';
import { Country, Offer } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';
import { maxAmount, minAmount } from '../../validators';

@Component({
  selector: 'buyer-offer',
  templateUrl: './buyer-offer.component.html',
  styleUrls: ['./buyer-offer.component.scss'],
})
export class BuyerOfferComponent implements OnChanges {
  @Input() modalId: string = '';

  @Input() offer: Offer | any;
  @Input() userProfile: any;
  @Output() buyerOffer: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closeModal') closeModal: any;

  @Input() countries: Country[] = [];

  transactionInfor: any = {};

  buyerOfferForm: FormGroup = new FormGroup({
    amount: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        minAmount('1'),
        maxAmount('100000'),
      ])
    ),
    biddingPurpose: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    paymentMethod: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    haveCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    wantCurrency: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    sellerUserId: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
  });

  transactionPurposeList: Array<string> = [
    'Allowance/Maintenance',
    'Educational Support',
    'Family Support',
    'Gift',
    'Investment',
    'Loan',
    'Medical payment',
    'Real Estate',
    'Rent',
    'School Fees',
    'Tax payment',
    'Trade transaction',
    'Travel',
    'Utilities',
  ];

  userDetails: any;

  iHave: Country | any;

  iWant: Country | any;

  currencySearchTerm: string = '';

  selectedPurpose: string = '';

  convertedRate: number = 0;

  modalId2: string = '#offerModal';

  constructor(
    public globals: GlobalsService,
    public transactionService: TransactionService
  ) {}

  async ngOnChanges() {
    this.buyerOfferForm.reset();
    this.buyerOfferForm.controls['amount'].setValidators([
      Validators.required,
      minAmount(this.offer?.minAmount?.toString() || '1'),
      maxAmount(this.offer?.amount?.toString() || '100000'),
    ]);
    this.buyerOfferForm.patchValue({
      haveCurrency: this.offer?.localCurrency,
      wantCurrency: this.offer?.foreignCurrency,
      paymentMethod: this.offer?.paymentReceiveThrough,
      sellerUserId: this.offer?.sellerUser?._id,
      buyerUserId: this.globals.user?._id,
    });
    this.iHave = this.globals.countries.find(
      (country) =>
        country.name.toLowerCase() ===
        (this.offer?.foreignCurrency?.country || 'united kingdom').toLowerCase()
    );
    this.iWant = this.globals.countries.find(
      (country) =>
        country.name.toLowerCase() ===
        (this.offer?.localCurrency?.country || 'united kingdom').toLowerCase()
    );
    await this.getGlobalRate();
    this.setViewOfferState();
  }

  get amount() {
    return this.buyerOfferForm.get('amount');
  }

  selectCurrencyFilter() {
    // Get the dropdown element
    let dropDown = document.querySelector('#currency-select-1') as HTMLElement;
    // Get the search input element
    let searchbox = document.querySelector(
      '#currencysearchbox1'
    ) as HTMLElement;
    // Get the dropdown input to hide inner elements
    let dropdownInput = document.querySelector(
      '#dropdown-input-1'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    dropdownInput.classList.toggle('active');
    // Reset the search input
    this.currencySearchTerm = '';
    // Set focus on search input
    searchbox.focus();

    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
        dropdownInput.classList.remove('active');
      }
    });
  }

  selectCurrencyFilter2() {
    // Get the dropdown element
    let dropDown = document.querySelector('#currency-select-2') as HTMLElement;
    // Get the search input element
    let searchbox = document.querySelector(
      '#currencysearchbox2'
    ) as HTMLElement;
    // Get the dropdown input to hide inner elements
    let dropdownInput = document.querySelector(
      '#dropdown-input-2'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    dropdownInput.classList.toggle('active');
    // Reset the search input
    this.currencySearchTerm = '';
    // Set focus on search input
    searchbox.focus();

    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
        dropdownInput.classList.remove('active');
      }
    });
  }

  selectCurrency(variable: any, country: any) {
    if (variable === 'have') {
      this.iHave = country;
      this.buyerOfferForm.controls['haveCurrency'].setValue(
        country.currencyCode
      );
    } else if (variable === 'want') {
      this.iWant = country;
      this.buyerOfferForm.controls['wantCurrency'].setValue(
        country.currencyCode
      );
    }
    let dropDown = document.querySelector('#country-select') as HTMLElement;
    dropDown.classList.remove(...'active');
  }

  startTransactionDropDown() {
    let dropDown = document.querySelector(
      '.transaction-dropdown'
    ) as HTMLElement;
    dropDown.classList.toggle('active');
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
      }
    });
  }

  selectPurpose(purpose: string) {
    this.selectedPurpose = purpose;
    this.buyerOfferForm.patchValue({
      biddingPurpose: purpose,
    });
  }

  formatAmount(event: any, type: string) {
    // format the amount to have commas and decimals
    let amount = event.target.value;
    amount = amount.replace(/,/g, '');
    amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    // allow only numbers, commas and decimals
    amount = amount.replace(/[^0-9,\.]/g, '');
    event.target.value = amount;
    this.buyerOfferForm.patchValue({
      [type]: amount,
    });
    // trigger validation
    this.buyerOfferForm.controls[type].markAsTouched();
    this.buyerOfferForm.controls[type].markAsDirty();
    this.buyerOfferForm.updateValueAndValidity();
  }

  formatToNumber(amount: string | number): number {
    if (typeof amount === 'number') return amount;
    return Number(amount?.replace(/,/g, '') || 0);
  }

  calcAndFormatAmount(amount: string | number, rate: number): string {
    let convertedAmount = this.formatToNumber(amount);
    let formattedAmount = (convertedAmount * rate).toFixed(2);
    formattedAmount = formattedAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return formattedAmount;
  }

  async getGlobalRate(
    want: string = this.iWant?.currency.toLowerCase(),
    have: string = this.iHave?.currency.toLowerCase()
  ) {
    let resp: any = await this.transactionService.getGlobalRate(want, have);
    this.convertedRate = resp.conversion_rate;
  }

  onSubmit() {
    delete this.buyerOfferForm.value['wantCurrency'];
    delete this.buyerOfferForm.value['haveCurrency'];
    delete this.buyerOfferForm.value['paymentMethod'];
    this.buyerOfferForm.value['amount'] = this.formatToNumber(
      this.buyerOfferForm.value['amount']
    );
    this.buyerOffer.emit(this.buyerOfferForm.value);
    this.buyerOfferForm.reset();
    this.closeModal.nativeElement.click();
  }

  setViewOfferState() {
    const { transactionPurpose, amount } = this.offer || {};
    const { _id: sellerId } = this.offer?.sellerUser || {};
    const { _id: buyerId } = this.globals.user || {};
    if (sellerId === buyerId) {
      this.buyerOfferForm.patchValue({
        biddingPurpose: transactionPurpose,
        amount: this.calcAndFormatAmount(amount, 1),
      });
    }
  }

  async archiveOffer(offerId: string) {
    this.globals.spinner.show();
    await this.transactionService.editOffer(offerId, true);
    await this.transactionService.getAllOffers();
    this.globals.spinner.hide();
  }
}
