// import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
// import { TransactionService } from 'src/app/services/api/transactions/transaction.service';
// import { GlobalsService } from 'src/app/services/core/globals';
// import { CreateWalletComponent } from '../create-wallet/create-wallet.component';

// @Component({
//   selector: 'app-create-wallet-form',
//   templateUrl: './create-wallet-form.component.html',
//   styleUrls: ['./create-wallet-form.component.scss']
// })
// export class CreateWalletFormComponent {
 
//   @Input() modalId: string = '';
//   @ViewChild('closeModal') closeModal!: ElementRef;
//   @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer!: ViewContainerRef;
//   @Input() userProfile: any;
//   modalId2: string = 'createWalletForm';
//   @Input() message: SafeHtml | undefined; 
//   phoneNumber: string = '';
//   bvn: string = '';
//   firstName: string = '';
//   lastName: string = '';
//   email: string = '';
//   referralCode: string = '';
//   errorMessage: string | null = null;
//   isModalOpen: boolean = false;
//   success = false;
//   successMessage: string = '';
//   constructor(
//     private cdr: ChangeDetectorRef,
//     private componentFactoryResolver: ComponentFactoryResolver,
//     private globals: GlobalsService,
//     private transactionService: TransactionService,
//     private sanitizer: DomSanitizer
//   ) {}

//   onClose(): void {
//     this.isModalOpen = false;
//     this.cdr.detectChanges();
//   }

//   step: number = 1;

//   onBack() {
//     this.step--;
//   }

//   onNext() {
//     this.step++;
//   }
//   isFormValid(): boolean {
//     return (
//       this.email.trim() !== '' &&
//       this.firstName.trim() !== '' &&
//       this.lastName.trim() !== '' &&
//       this.bvn.trim() !== '' &&
//       this.phoneNumber.trim() !== ''
//     );
//   }
//   async onSubmit() {
//     try {
//       this.globals.spinner.show();
//       const walletDetails = {
//         phoneNumber: this.phoneNumber || '',
//         firstName: this.firstName || '',
//         bvn: this.bvn || '',
//         lastName: this.lastName || '',
//         referralCode: this.referralCode || '',
//         email: this.email || ''
//       };

//       if (!this.userProfile || !this.userProfile._id) {
//         throw new Error('User profile is not set or missing _id');
//       }

//       const userId = this.userProfile._id;
//       const response = await this.transactionService.createWallet(userId, walletDetails);

//       // Clear input fields
//       this.phoneNumber = '';
//       this.bvn = '';
//       this.firstName = '';
//       this.lastName = '';
//       this.email = '';
//       this.referralCode = '';

//       // Detect changes to update the UI
//       this.cdr.detectChanges();

//       // Show success message
//       this.success = true;
//       this.successMessage = response.message;

//     } catch (error) {
//       const errorMessage = error instanceof Error ? error.message : 'User already has a wallet';
//       console.error('Error saving account details:', errorMessage);
//       this.globals.toast.error(errorMessage);
//       this.errorMessage = 'An error occurred while saving the account details';
//     } finally {
//       this.globals.spinner.hide();
//     }
//   }
// }


import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TransactionService } from 'src/app/services/api/transactions/transaction.service';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'app-create-wallet-form',
  templateUrl: './create-wallet-form.component.html',
  styleUrls: ['./create-wallet-form.component.scss']
})
export class CreateWalletFormComponent {
 
  @Input() modalId: string = '';
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer!: ViewContainerRef;
  @Input() userProfile: any;
  modalId2: string = 'createWalletForm';
  @Input() message: SafeHtml | undefined; 
  phoneNumber: string = '';
  bvn: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  referralCode: string = '';
  errorMessage: string | null = null;
  isModalOpen: boolean = false;
  success = false;
  successMessage: string = '';
  showFundWalletFields: boolean = false;
  modalTitle: string = 'Create Wallet';
  constructor(
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private globals: GlobalsService,
    private transactionService: TransactionService,
    private sanitizer: DomSanitizer
  ) {}

  onClose(): void {
    this.isModalOpen = false;
    this.cdr.detectChanges();
  }

  step: number = 1;

  onBack() {
    this.step--;
  }

  onNext() {
    this.step++;
  }

  isFormValid(): boolean {
    return (
      this.email.trim() !== '' &&
      this.firstName.trim() !== '' &&
      this.lastName.trim() !== '' &&
      this.bvn.trim() !== '' &&
      this.phoneNumber.trim() !== ''
    );
  }

  validateForm() {
    this.cdr.detectChanges();
  }

  async onSubmit() {
    try {
      this.globals.spinner.show();
      const walletDetails = {
        phoneNumber: this.phoneNumber || '',
        firstName: this.firstName || '',
        bvn: this.bvn || '',
        lastName: this.lastName || '',
        referralCode: this.referralCode || '',
        email: this.email || ''
      };

      if (!this.userProfile || !this.userProfile._id) {
        throw new Error('User profile is not set or missing _id');
      }

      const userId = this.userProfile._id;
      const response = await this.transactionService.createWallet(userId, walletDetails);

      // Clear input fields
      this.phoneNumber = '';
      this.bvn = '';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.referralCode = '';
      this.cdr.detectChanges();
      
      this.success = true;
      this.successMessage = response.message || 'Wallet created successfully';

    } catch (error) {
      this.errorMessage = 'Failed to create wallet. Please try again.';
      this.globals.toast.error('user already have a wallet'); 
      console.error('Error creating wallet:', error);
    } finally {
      this.globals.spinner.hide();
    }
  }
  toggleFundWalletFields() {
    this.success = false;
    this.showFundWalletFields = true;
    this.modalTitle = 'Fund Wallet';
  }
  getNumbersFromMessage(message: string): string {
    const numbers = message.match(/\d+/g);
    return numbers ? numbers.join(' ') : '';
  }
}
