import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, Notification, OTP, State, User } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';
import { MixpanelService } from '../mixpanel/mixpanel.service';

const routes = {
  register: 'auth/sign-up',
  resendOTP: 'auth/resend-otp',
  verifyOTP: 'auth/verify-otp',
  login: 'auth/login',
  forgotPassword: 'auth/forget-password',
  resetPassword: 'auth/reset-password',
  changePassword: 'auth/change-password',
  user: 'profile',
  updateSocialsUrl: 'profile/socials',
  updateCurrency: 'profile/currency',
  notifications: 'notifications',
  swapPals: 'swap-pals',
  updateAvatar: 'profile/avatar',
  countries: 'countries',
  verifyPhone: 'auth/add-phone',
  kyc: 'kyc/latest',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User | any;
  currentAccessToken = null;
  swapPals: User[] = [];
  swapPalPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    sort: {
      status: 'all',
    },
  };

  kycStatus: string = '';

  constructor(
    private api: RequestService,
    private globals: GlobalsService,
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) {}

  async updateCurrency(userCurrencies: Array<any>) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.updateCurrency, {
          currencies: userCurrencies,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getUserLocation(): Promise<any> {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http
          .get('https://ipapi.co/json/')
          .toPromise();
        this.globals.storage.setUserLocationDetails(resp);
        resolve(resp);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  // Function to register
  async register(user: User | any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.register, user);
        this.user = resp.data as User;
        this.globals.storage.setUserDetails(this.user);
        this.mixpanelService.signUpEvent({
          email: this.user.email,
          status: 'success',
          authenticationMethod: this.user.authenticationMethod,
          firstName: this.user.name.first,
          lastName: this.user.name.last,
          referredBy: this.user.referredBy,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        this.mixpanelService.signUpEvent({
          email: user.email,
          status: 'failure',
          firstName: user.firstName,
          lastName: user.lastName,
          referredBy: user.referredBy,
          authenticationMethod: user.authenticationMethod,
        });
        reject(ex);
      }
    });
  }

  // Function to send forgot password email
  async forgotPassword(user: User) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.forgotPassword, user);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to send forgot password email
  async resetPassword(user: User) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.resetPassword, user);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to change password
  async changePassword(data: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.changePassword, data);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to get user details
  async getUserDetails() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.user);
        this.user = resp.data as User;
        this.globals.storage.setUserDetails(this.user);
        this.globals.user = this.user;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getUserById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`${routes.user}/${id}`);
        this.user = resp.data as User;
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to verifyOTP
  async verifyOTP(otp: OTP) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.verifyOTP, otp);
        this.globals.storage.setAccessToken(resp.token);
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to resendOTP
  async resendOTP(otp: OTP) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.resendOTP, {
          email: otp.email,
        });
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to login
  async login(data: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.login, data);
        this.user = resp.data as User;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        if (ex?.toLowerCase().includes('please verify your account')) {
          await this.resendOTP({
            email: data.email,
            otp: '',
          });
          this.globals.storage.setUserRegDetails({ email: data.email });
          this.globals.router.navigateByUrl('/auth/verify');
        }
        this.mixpanelService.identifyUser(data.email);
        this.mixpanelService.signInUserEvent({
          email: data.email,
          status: 'failure',
          authenticationMethod: data.authenticationMethod,
          username: '',
        });
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async updateSocials(data: { facebook: string; instagram: string }) {
    return await new Promise(async (resolve, reject) => {
      try {
        // Show the loading spinner
        this.globals.spinner.show();

        // Send a PATCH request to the server with the new profile information
        const resp: any = await this.api.patch(routes.updateSocialsUrl, data);

        // Hide the loading spinner
        this.globals.spinner.hide();

        // Log the successful update to Mixpanel
        this.globals.mixpanel.verifyTier2Event(
          this.globals.user?.username, // The user's username
          data.facebook ?? data.instagram, // The profile URL
          data.facebook ? 'facebook' : 'instagram', // The social media platform
          'success' // The status of the update
        );

        // Show a success toast message
        this.globals.toast.success(resp.message);

        // Resolve the promise with the server response
        resolve(resp);
      } catch (ex: any) {
        // Hide the loading spinner
        this.globals.spinner.hide();

        // Show an error toast message
        this.globals.toast.error(ex);

        // Log the failed update to Mixpanel
        this.globals.mixpanel.verifyTier2Event(
          this.globals.user?.username, // The user's username
          data.facebook ?? data.instagram, // The profile URL
          data.facebook ? 'facebook' : 'instagram', // The social media platform
          'failure', // The status of the update
          ex // The error message
        );

        // Reject the promise with the error
        reject(ex);
      }
    });
  }
  async getNotifications(page: number) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.notifications}?page=${page}`
        );
        this.globals.notifications = resp.data as Notification[];
        this.globals.storage.setNotifications(this.globals.notifications);
        this.globals.unreadMessages = false;
        this.globals.numberOfUnreadMessages = 0;
        for (let i = 0; i < this.globals.notifications.length; i++) {
          const element = this.globals.notifications[i];
          if (!element.markedAsRead) {
            this.globals.numberOfUnreadMessages++;
            if (this.globals.numberOfUnreadMessages > 0)
              this.globals.unreadMessages = true;
          }
        }
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async markNotificationAsRead(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.patch(
          `${routes.notifications}/${id}`,
          {}
        );
        this.globals.notifications = this.globals.notifications.map(
          (notification) => {
            if (notification._id === id) {
              notification.markedAsRead = true;
              this.globals.numberOfUnreadMessages--;
              if (this.globals.numberOfUnreadMessages <= 0)
                this.globals.numberOfUnreadMessages = 0;
            }

            if (id === '') {
              notification.markedAsRead = true;
            }
            return notification;
          }
        );
        this.globals.storage.setNotifications(this.globals.notifications);
        this.globals.unreadMessages = false;
        for (let i = 0; i < this.globals.notifications.length; i++) {
          const element = this.globals.notifications[i];
          if (!element.markedAsRead) {
            this.globals.unreadMessages = true;
            break;
          }
        }
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async getAllSwapPals(
    page: number = this.swapPalPagination.page,
    itemsPerPage: number = this.swapPalPagination.itemsPerPage,
    sort: string = this.swapPalPagination.sort.status,
    search: string = this.swapPalPagination.search
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.swapPals}?page=${page}&itemsPerPage=${itemsPerPage}&status=${sort}&search=${search}`
        );
        this.swapPals = resp.data.pals as User[];
        this.swapPalPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }

  async addSwapPal(swapPalId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.swapPals, {
          swapPalId,
          follow: true,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async removeSwapPal(swapPalId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.swapPals, {
          swapPalId,
          follow: false,
        });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async updateProfile(data: any, message: string = '') {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.user, data);
        this.globals.storage.setUserDetails({
          ...this.globals.user,
        });
        this.globals.toast.success(message || resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async updateAvatar(avatar: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(routes.updateAvatar, { avatar });
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getAllCountriesData() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.countries);
        this.globals.countries = resp.data as Country[];
        this.globals.storage.setCountries(this.globals.countries);
        resolve(resp);
      } catch (ex: any) {
        reject(ex || 'Internal Server Error');
      }
    });
  }

  async getAllStatesData(countryId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.countries}/${countryId}`
        );
        this.globals.states = resp.data.states as State[];
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async verifyPhone(phone: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(routes.verifyPhone, { phone });
        this.globals.toast.success(resp.message);
        this.globals.mixpanel.verifyTier1Event(
          this.globals.user?.username,
          phone,
          'success'
        );
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        this.globals.mixpanel.verifyTier1Event(
          this.globals.user?.username,
          phone,
          'failed',
          ex
        );
        reject(ex);
      }
    });
  }

  async getLatestKYC() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(`${routes.kyc}`);
        this.kycStatus = !resp.data
          ? ''
          : resp.data.finalStatus?.toLowerCase() ?? 'pending';
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }
}
