<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header justify-content-end border-0">
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body row transactionModal m-3 mt-0 pt-0">
    <div class="col-12 mb-5">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="d-flex justify-content-center align-items-center flex-column gap-1"
        >
          <img
            loading="lazy"
            [src]="
              globals.user?._id == transaction?.bidding?.seller?.user?._id
                ? transaction?.bidding?.buyer?.user?.avatar
                : transaction?.bidding?.seller?.user?.avatar ||
                  'assets/icons/user.svg'
            "
            class="rounded-circle pointer"
            alt="avatar"
            width="83"
            height="83"
            (click)="
              navigate(
                globals.user?._id == transaction?.bidding?.seller?.user?._id
                  ? transaction?.bidding?.buyer?.user?._id
                  : transaction?.bidding?.seller?.user?._id
              )
            "
          />
          <div class="text-center">
            <p class="mb-0 fw-bolder username">
              {{
                globals.user?._id == transaction?.bidding?.seller?.user?._id
                  ? transaction?.bidding?.buyer?.user?.username
                  : transaction?.bidding?.seller?.user?.username
              }}
            </p>
            <p class="mb-0 text-muted">
              {{
                globals.user?._id == transaction?.bidding?.seller?.user?._id
                  ? "Buyer"
                  : "Seller"
              }}
            </p>
          </div>
          <div class="mt-3 text-center">
            <p class="mb-0 fw-bolder fw-500 font-14 text-muted">
              {{
                globals.user?._id == transaction?.bidding?.seller?.user?._id
                  ? "Will Receive"
                  : "Wants"
              }}
            </p>
            <h3 class="my-2 text-dark font-28">
              {{
                globals.user?._id == transaction?.bidding?.seller?.user?._id
                  ? (transaction?.bidding?.amount || 0
                    | currency
                      : transaction?.localCurrency?.code
                      : "symbol-narrow")
                  : (transaction?.bidding?.amount * transaction?.rate || 0
                    | currency
                      : transaction?.foreignCurrency?.code
                      : "symbol-narrow")
              }}
            </h3>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-6 d-flex align-items-center">
          <p class="mb-0 text-muted">Status</p>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center">
          <p
            class="mb-0 label"
            [ngClass]="{
              'secondary': transaction?.bidding?.biddingStatus === 'pending',
              'primary': transaction?.bidding?.biddingStatus === 'ongoing',
            }"
          >
            {{ transaction?.bidding?.biddingStatus | titlecase }}
          </p>
        </div>
      </div>
      <div class="row mb-3 detail-container">
        <div
          class="mb-2 col-12 d-flex align-items-center justify-content-center"
        >
          <p class="mb-0 font-12 detail fw-bolder">
            {{
              globals.user?._id == transaction?.bidding?.seller?.user?._id
                ? "Offer Details"
                : "Seller Detail"
            }}
          </p>
        </div>
        <div class="row g-0 gy-3 mb-3 mt-0">
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Payment method</p>
              <p class="mb-0 font-12 text-muted">
                {{
                  globals.user?._id == transaction?.bidding?.seller?.user?._id
                    ? transaction?.paymentReceiveThrough
                    : transaction?.paymentSendFrom
                }}
              </p>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Transaction ID</p>
              <p class="mb-0 font-12 text-muted">
                #{{ transaction?._id?.slice(-8) }}
              </p>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Rate</p>
              <p class="mb-0 font-12 text-muted">
                {{ transaction?.rate }}
              </p>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Date</p>
              <p class="mb-0 font-12 text-muted">
                {{ transaction?.updatedAt | date : "dd MMM yyyy hh:mm a" }}
              </p>
            </div>
          </div>
          <div
            class="col-6 d-flex align-items-center gap-2"
            *ngIf="globals.user?._id == transaction?.bidding?.seller?.user?._id"
          >
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Country</p>
              <p class="mb-0 font-12 text-muted">
                <ng-container
                  *ngFor="
                    let country of transaction?.preferredCountries;
                    let i = index
                  "
                >
                  {{ country.country }}
                  {{
                    i < transaction?.preferredCountries?.length - 1 ? "," : ""
                  }}
                </ng-container>
              </p>
            </div>
          </div>
          <div
            class="col-6 d-flex align-items-center gap-2"
            *ngIf="globals.user?._id != transaction?.bidding?.seller?.user?._id"
          >
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Amount Available</p>
              <p class="mb-0 font-12 text-muted">
                {{
                  transaction?.amount
                    | currency
                      : transaction?.localCurrency?.code
                      : "symbol-narrow"
                }}
              </p>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Limit</p>
              <p class="mb-0 font-12 text-muted">
                {{
                  transaction?.minAmount
                    | currency
                      : transaction?.localCurrency?.code
                      : "symbol-narrow"
                }}
                -
                {{
                  transaction?.amount
                    | currency
                      : transaction?.localCurrency?.code
                      : "symbol-narrow"
                }}
              </p>
            </div>
          </div>
          <div
            class="col-6 d-flex align-items-center gap-2"
            *ngIf="globals.user?._id != transaction?.bidding?.seller?.user?._id"
          >
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Response Time</p>
              <p class="mb-0 font-12 text-muted">
                {{
                  transaction?.bidding?.seller?.user?.metrics?.biddings
                    ?.averageResponseTime
                }}
              </p>
            </div>
          </div>
          <div
            class="col-6 d-flex align-items-center gap-2"
            *ngIf="globals.user?._id != transaction?.bidding?.seller?.user?._id"
          >
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Preferred Countries</p>
              <p class="mb-0 font-12 text-muted">
                <ng-container
                  *ngFor="
                    let country of transaction?.preferredCountries;
                    let i = index
                  "
                >
                  <img
                    loading="lazy"
                    [src]="
                      globals.searchCountryBy(country?.country, 'name')?.flag
                    "
                    alt=""
                    class="me-1"
                    style="width: 15px; height: 15px; object-fit: contain"
                  />
                  {{ country.country }}
                  {{
                    i < transaction?.preferredCountries?.length - 1 ? "," : ""
                  }}
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        class="viewBtn fw-500"
        *ngIf="transaction?.bidding?.biddingStatus !== 'pending'"
        (click)="navigateToChat(transaction?._id)"
      >
        View Chat
      </button>
      <div
        class="row g-0 gx-2"
        *ngIf="
          transaction?.bidding?.biddingStatus === 'pending' &&
          transaction?.bidding?.seller?.user?._id === globals.user?._id
        "
      >
        <div class="col-6">
          <button class="viewBtn fw-500" (click)="displayUpgradeModal()">
            Decline
          </button>
        </div>
        <div class="col-6">
          <button
            class="viewBtn fw-500 approve"
            (click)="displayUpgradeModal(true)"
          >
            Approve
          </button>
        </div>
      </div>
      <button
        class="viewBtn cancel fw-500"
        *ngIf="
          transaction?.bidding?.biddingStatus === 'pending' &&
          transaction?.bidding?.seller?.user?._id !== globals.user?._id
        "
        (click)="cancelBidding()"
      >
        Cancel
      </button>
    </div>
  </div>
</swap-modal>

<!-- View Proof Modal -->
<view-payment-proof
  [modalId]="modalId10"
  [imageURL]="paymentProof"
></view-payment-proof>
