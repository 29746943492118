import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(itemList: any[], searchTerm: string): any[] {
    if (!itemList || !searchTerm) return itemList;

    return itemList.filter((itemList) =>
      itemList.item.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  }
}
