import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from '../../../services/core/globals';
import { environment } from '../../../../environments/environment';
import { phoneValidator } from '../../validators';

@Component({
  selector: 'verify-phone-nos',
  templateUrl: './verify-phone-nos.component.html',
  styleUrls: ['./verify-phone-nos.component.scss'],
})
export class VerifyPhoneNosComponent {
  @Input() modalId = '';
  @Input() modalId2 = '';
  @Input() phone = '';
  @Output() verifyPhone = new EventEmitter<any>();
  @ViewChild('closeModal') closeModal: any;

  phoneVerificationForm: FormGroup = new FormGroup({
    phone: new FormControl('', [Validators.required, phoneValidator()]),
    verificationCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.pattern('^[0-9]+$'),
    ]),
  });
  firebaseApp: any;
  firebaseAuth: any;

  constructor(public globals: GlobalsService) {
    Promise.all([import('firebase/auth'), import('firebase/app')]).then(
      (res) => {
        this.firebaseApp = res[1].default.initializeApp(
          environment.firebaseConfig
        );
        this.firebaseAuth = res[1].default.auth;
      }
    );
  }

  ngOnInit() {
    if (this.phone) {
      this.phoneVerificationForm.controls['phone'].setValue(this.phone);
    }
  }

  onSubmit() {
    if (this.phoneVerificationForm.valid) {
      this.signInWithCredential();
    }
  }

  onValueChange(input: string, value: string) {
    this.phoneVerificationForm.get(input)?.setValue(value);
    this.phoneVerificationForm.get(input)?.markAsTouched();
    this.phoneVerificationForm.get(input)?.markAsDirty();
    this.phoneVerificationForm.updateValueAndValidity();
  }

  generateOTP() {
    this.renderRecaptchaVerifier().then((res) => {
      this.signInWithPhoneNumber(res);
    });
  }

  async pasteCode() {
    try {
      const code = await navigator.clipboard.readText();
      this.phoneVerificationForm.get('verificationCode')?.setValue(code);
    } catch (error) {
      console.error(error);
    }
  }

  private signInWithCredential() {
    const otp = this.phoneVerificationForm.value.verificationCode as string;
    const verificationId = this.globals.storage.getOtpVerificationId();
    const credential = this.firebaseAuth.PhoneAuthProvider.credential(
      verificationId,
      otp
    );
    this.firebaseAuth()
      .signInWithCredential(credential)
      .then((res: any) => {
        this.verifyPhone.emit(this.phoneVerificationForm.value);
        this.phoneVerificationForm.reset();
        this.closeModal.nativeElement.click();
        this.globals.openModal(this.modalId2);
      })
      .catch((error: any) => {
        this.globals.mixpanel.verifyTier1Event(
          this.globals.user?.username,
          this.phoneVerificationForm.value.phone as string,
          'failed',
          error
        );
      });
  }

  private async renderRecaptchaVerifier() {
    const recaptchaVerifier = new this.firebaseAuth.RecaptchaVerifier(
      'otp-button',
      {
        size: 'invisible',
      }
    );
    recaptchaVerifier.render();
    return recaptchaVerifier;
  }

  private signInWithPhoneNumber(recaptchaVerifier: any) {
    const phoneNumber = this.phoneVerificationForm.value.phone as string;
    this.firebaseAuth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((resp: any) => {
        this.globals.toast.success('OTP sent successfully');
        this.globals.storage.setOtpVerificationId(resp.verificationId);
        recaptchaVerifier.clear();
      })
      .catch((error: any) => {
        this.globals.toast.error(error.message);
        recaptchaVerifier.clear();
      });
  }
}
