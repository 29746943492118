import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/api/auth/auth.service';
import { MixpanelService } from '../../../services/api/mixpanel/mixpanel.service';
import { SocketService } from '../../../services/api/socketio/socket.service';
import { TransactionService } from '../../../services/api/transactions/transaction.service';
import { GlobalsService } from '../../../services/core/globals';
import { HintsService } from '../../../services/core/hints';
import { Offer, Ratings_Reviews, User } from '../../../services/core/IApp';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  title: string = '';

  userDetails: User | undefined;

  isExpanded: boolean = true;

  @Input() isSidebarOpen: boolean = false;

  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();

  modalId: string = 'offerModal';
  modalId2: string = 'buyerOfferModal';
  modalId3: string = 'ratingsReviewModal';
  modalId4: string = 'upgradeModal';
  modalId5: string = 'verifyPhoneModal';

  connection: any;

  constructor(
    private titleService: Title,
    public globals: GlobalsService,
    public transactionService: TransactionService,
    private hintsService: HintsService,
    private mixpanelService: MixpanelService,
    public authService: AuthService,
    private socketService: SocketService,
    private renderer: Renderer2, private el: ElementRef
  ) {
    this.globals.router.events.subscribe((val) => {
      this.title = this.titleService.getTitle();
    });
  }

  async ngOnInit() {
    await this.authService.getNotifications(1);
  }

  ngOnDestroy() {
    this.stopListener();
  }

  stopListener() {
    this.connection?.unsubscribe();
  }

  // selectDropdown(event: any) {
  //   // close other dropdown options
  //   event.preventDefault();
  //   let mainElement = event.target;
  //   const element = document.querySelectorAll('.dropdown-options');
  //   element.forEach((el) => {
  //     if (
  //       el.classList.contains('show') &&
  //       el !== mainElement.nextElementSibling
  //     ) {
  //       el.classList.remove('show');
  //     }
  //   });

  //   if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

  //   // add show class to dropdown options
  //   for (let i = 0; i < mainElement.children.length; i++) {
  //     if (mainElement.children[i].classList.contains('dropdown-options')) {
  //       mainElement.children[i].classList.toggle('show');
  //     }
  //   }

  //   // close dropdown options on click outside
  //   document.addEventListener('mousedown', (e: any) => {
  //     const element = document.querySelectorAll('.dropdown-options');
  //     element.forEach((el) => {
  //       if (!el.contains(e.target)) {
  //         el.classList.remove('show');
  //       }
  //     });
  //   });
  // }

  selectDropdown(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  
    let mainElement = event.target as HTMLElement;
  
    // Handle if the click is on the image inside the button
    if (mainElement.nodeName === 'IMG' && mainElement.parentElement?.nodeName === 'BUTTON') {
      mainElement = mainElement.parentElement;
    }
  
    // Close other dropdown options
    const elements = this.el.nativeElement.querySelectorAll('.dropdown-options.show');
    elements.forEach((el: HTMLElement) => {
      if (el !== mainElement.querySelector('.dropdown-options')) {
        this.renderer.removeClass(el, 'show');
      }
    });
  
    // Toggle current dropdown
    const dropdownElement = mainElement.querySelector('.dropdown-options');
    if (dropdownElement) {
      if (dropdownElement.classList.contains('show')) {
        this.renderer.removeClass(dropdownElement, 'show');
      } else {
        this.renderer.addClass(dropdownElement, 'show');
      }
    }
  }
  
  @HostListener('document:mousedown', ['$event'])
  closeDropdown(event: Event) {
    const elements = this.el.nativeElement.querySelectorAll('.dropdown-options.show');
    elements.forEach((el: HTMLElement) => {
      if (!el.contains(event.target as Node) && !(event.target as HTMLElement).closest('.notification')) {
        this.renderer.removeClass(el, 'show');
      }
    });
  }
  

  async updateOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.updateOffer(
      offer,
      this.transactionService.offer._id
    );
    await this.transactionService.getAllMyOffers();
    this.globals.spinner.hide();
  }

  async createOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.createOffer(offer);
    this.globals.spinner.hide();
    this.globals.user?.verificationStatus?.rank !== 3 &&
      this.globals.openModal(this.modalId4);
  }

  async buyerOffer(offer: Offer) {
    this.globals.spinner.show();
    await this.transactionService.buyOffer(
      offer,
      this.transactionService.offer._id
    );
    this.globals.spinner.hide();
    this.globals.user?.verificationStatus?.rank !== 3 &&
      this.globals.openModal(this.modalId4);
    this.hintsService.thirdStageHints();
    setTimeout(() => {
      this.globals.closeModal('#' + this.modalId4);
      this.globals.router.navigate(['/main/transaction'], {
        queryParams: { tab: 'pending' },
      });
    }, 5000);
  }

  async emitRatingReview(ratingReview: Ratings_Reviews) {
    this.globals.spinner.show();
    await this.transactionService.createReview(ratingReview);
    this.globals.spinner.hide();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  async markAsRead(
    notificationId: string,
    trigger?: string,
    biddingId?: string
  ) {
    trigger && this.globals.navigateByTrigger(trigger, biddingId);
    await this.authService.markNotificationAsRead(notificationId);
  }

  resetOffer() {
    this.transactionService.offer = {} as Offer;
  }

  async verifyPhone(form: any) {
    this.globals.spinner.show();
    await this.authService.verifyPhone(form.phone);
    await this.authService.getUserDetails();
    this.globals.closeModal('#' + this.modalId5);
    this.globals.spinner.hide();
  }

  async logOut() {
    await this.globals.loggedOut();
    this.socketService.disconnectSocket();
    this.mixpanelService.signOutUserEvent({
      username: this.globals.user?.username,
      status: 'success',
    });
  }
}
