import { Injectable } from '@angular/core';
import { Messages, Transaction } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

const routes = {
  messages: 'messages',
  requestEndTransaction: 'messages/terminate-negotiation/request',
  requestExtendTime: 'messages/extend-negotiation-time/request',
  reportTransaction: 'bidding-reports',
  extendTime: 'messages/extend-negotiation-time/approval',
  sendMessages: 'messages/send',
  confirmMoneyReceived: 'messages/money-received',
  proofOfPayment: 'messages/proof-of-payment',
  terminateTransaction: 'messages/terminate-negotiation/approval',
};

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  transactionMessage: Messages = {} as Messages;
  transaction: Transaction | any;
  messages: Array<any> = [];
  totalItemsCount: number = 0;
  page: number = 1;

  constructor(private api: RequestService, private globals: GlobalsService) {}

  // Function to get transaction messages by bidding id
  async getMessagesByBiddingId(biddingId: string, page: number = this.page) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.messages}/${biddingId}?page=${page}`
        );
        this.messages = [...this.messages, ...resp.data.messages];
        this.transactionMessage = resp.data as Messages;
        this.transaction = resp.data.transaction as Transaction;
        this.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to request end a transaction
  async requestEndTransaction(
    offerId: string,
    bidding_id: string,
    messageId: string
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `${routes.requestEndTransaction}/${bidding_id}`,
          {
            offerId,
            messageId,
          }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to request end a transaction
  async endTransaction(
    offerId: string,
    bidding_id: string,
    messageId: string,
    contentId: string,
    approval: boolean
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `${routes.terminateTransaction}/${bidding_id}`,
          { offerId, messageId, contentId, approval }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to extend time of a transaction
  async requestExtendTime(
    offerId: string,
    bidding_id: string,
    messageId: string,
    extendNegotiationTime: number
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(
          `${routes.requestExtendTime}/${bidding_id}`,
          { offerId, messageId, extendNegotiationTime }
        );
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to report a transaction
  async reportTransaction(bidding_id: string, complaint: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `${routes.reportTransaction}/${bidding_id}`,
          { complaint }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        // if (ex?.status == 304) {
        //   this.globals.toast.error(
        //     'You have already reported this transaction'
        //   );
        //   return reject(ex);
        // }
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to accept an extention of negotiation time
  async acceptExtendTime(
    offerId: string,
    bidding_id: string,
    messageId: string,
    contentId: string,
    approval: boolean
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `${routes.extendTime}/${bidding_id}`,
          {
            offerId,
            messageId,
            contentId,
            approval,
          }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }


  // This function is used to send a message to a transaction
  async sendMessage(
    transaction_id: string,
    bidding_id: string,
    message_id: string,
    content: string
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.update(
          `${routes.sendMessages}/${transaction_id}/${bidding_id}/${message_id}`,
          { content }
        );
        resolve(resp);
      } catch (ex: any) {
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to confirm money received
  async confirmMoneyReceived(
    offerId: string,
    biddingId: string,
    messageId: string
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(
          `${routes.confirmMoneyReceived}/${biddingId}`,
          {
            offerId,
            messageId,
          }
        );
        this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to upload proof of payment
  async uploadProofOfPayment(bidding_id: string, proof: any) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          `${routes.proofOfPayment}/${bidding_id}`,
          proof
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to get updated proof of payment
  async getProofOfPayment(bidding_id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `${routes.proofOfPayment}/${bidding_id}`
        );
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        reject(ex);
      }
    });
  }
}
