<swap-modal [modalId]="modalId" [centered]="true">
  <div class="position-relative">
    <button class="close" (click)="closeModal.click()">
      <i class="bi bi-x"></i>
    </button>
  </div>
  <button
    type="button"
    data-bs-dismiss="modal"
    class="d-none closeBtn"
    aria-label="Close"
    #closeModal
  ></button>
  <div class="modal-body mt-0 mx-3">
    <div class="">
      <h1 class="font-24 mt-4 fw-700 color-header text-center">
        Terms and Conditions
      </h1>
      <div
        class="font-14 my-3 fw-400 color-text mb-3"
        id="terms-and-conditions"
        style="text-align: justify; height: 300px; overflow-y: scroll"
      >
        <p class="mb-0">
          Welcome to Google and Facebook! Before you create an account, please
          read and agree to the following Terms and Conditions ("Terms"). By
          signing up for an account, you acknowledge that you have read,
          understood, and agreed to be bound by these Terms. If you do not agree
          with any part of these Terms, you may not create an account.<br />
        </p>
        <ol>
          <li>Account Creation</li>
          <ol type="a">
            <li>
              You must be at least 13 years old to create an account on Google
              and Facebook.
            </li>
            <li>
              You agree to provide accurate and complete information during the
              signup process. You also agree to keep your account information
              updated.
            </li>
            <li>
              You are solely responsible for maintaining the confidentiality of
              your account credentials and for all activities that occur under
              your account. You agree to notify Google and Facebook immediately
              of any unauthorized use of your account.
            </li>
          </ol>
          <li>User Content</li>
          <ol type="a">
            <li>
              The Services provided by Google and Facebook may allow you to
              post, upload, or otherwise submit content, including text, images,
              and videos. You retain ownership of any intellectual property
              rights that you hold in the content.
            </li>
            <li>
              By submitting content, you grant Google and Facebook a
              non-exclusive, worldwide, royalty-free, sublicensable, and
              transferable license to use, reproduce, distribute, modify, adapt,
              publicly display, and publicly perform the content in connection
              with the Services.
            </li>
            <li>
              You are solely responsible for the content you submit and the
              consequences of sharing or publishing it. You represent and
              warrant that you have all necessary rights to submit the content
              and that it does not violate any laws or infringe upon any
              third-party rights.
            </li>
          </ol>
          <li>Privacy</li>
          <ol type="a">
            <li>
              Your privacy is important to us. Please refer to our Privacy
              Policy for information on how Google and Facebook collect, use,
              and protect your personal information.
            </li>
          </ol>
          <li>Prohibited Conduct</li>
          <ol type="a">
            <li>
              You agree not to engage in any of the following prohibited
              activities while using the Services:
            </li>
            <ol type="i">
              <li>Violating any applicable laws or regulations.</li>
              <li>
                Posting or sharing content that is illegal, offensive, abusive,
                defamatory, or violates the rights of others.
              </li>
              <li>
                Using the Services for any unlawful or unauthorized purpose.
              </li>
              <li>
                Interfering with or disrupting the integrity or performance of
                the Services.
              </li>
              <li>
                Attempting to gain unauthorized access to any account or
                computer system.
              </li>
              <li>
                Engaging in any other activity that is deemed inappropriate by
                Google and Facebook.
              </li>
            </ol>
          </ol>
          <li>Termination</li>
          <ol type="a">
            <li>
              Google and Facebook reserve the right to suspend or terminate your
              account, at their sole discretion, for any reason or no reason at
              all. In the event of termination, you will lose access to your
              account and any associated data.
            </li>
            <li>
              You may also delete your account at any time by following the
              instructions provided in the account settings.
            </li>
          </ol>
          <li>Modifications to the Terms</li>
          <ol type="a">
            <li>
              Google and Facebook may modify these Terms at any time. The
              modified Terms will be effective immediately upon posting. Your
              continued use of the Services after the posting of the modified
              Terms constitutes your acceptance of the changes.
            </li>
          </ol>
          <li>Governing Law</li>
          <ol type="a">
            <li>
              These Terms shall be governed by and construed in accordance with
              the laws of the jurisdiction in which Google and Facebook are
              incorporated, without regard to its conflict of law provisions.
            </li>
          </ol>
        </ol>
      </div>
      <div class="text-center mb-3">
        <button
          class="submit-btn"
          style="width: 260px;"
          (click)="emitTermsAndConditions.emit()"
          [disabled]="!enableButton"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</swap-modal>
