<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title fs-5 fw-bolder">{{ modalTitle }}</h2>
    <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    #closeBtn
    (click)="onClose()"
  ></button>
  </div>
  <div class="modal-body mx-3">
    <form (ngSubmit)="onSubmit()" #walletForm="ngForm" class="row g-3">
      <!-- Step 1 Content -->
      <div *ngIf="step === 1 && !success && !showFundWalletFields">
        <div class="d-flex align-items-center justify-content-center text-center" style="margin-top: 10rem;">
          <div>
            <img src="assets/images/Object1.png" alt="Create Wallet">
          </div>
        </div>
        <button class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700" (click)="onNext()" style="margin-top: 3rem;">Create Wallet</button>
      </div>

      <!-- Step 2 Content -->
      <div *ngIf="step === 2 && !success && !showFundWalletFields">
        <div class="col-12 mt-4">
          <p class="fw-700 mb-2 font-14">Email Address<span class="text-danger">*</span></p>
          <div class="input-shape d-flex align-items-center p-3">
            <input class="font-14 color-text fw-700 me-2 amount-input" type="text" [(ngModel)]="email" name="email" id="email" (input)="validateForm()" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <p class="color-header fw-700 mb-2 font-14">First Name<span class="text-danger">*</span></p>
            <div class="input-shape p-3">
              <input [(ngModel)]="firstName" class="font-14 w-100 color-text fw-700" type="text" name="firstName" id="firstName" (input)="validateForm()" />
            </div>
          </div>
          <div class="col-6">
            <p class="color-header fw-700 mb-2 font-14">Last Name<span class="text-danger">*</span></p>
            <div class="input-shape p-3">
              <input [(ngModel)]="lastName" class="font-14 w-100 color-text fw-700" type="text" name="lastName" id="lastName" (input)="validateForm()" />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <p class="color-header fw-700 mb-2 font-14">Bvn<span class="text-danger">*</span></p>
            <div class="input-shape p-3">
              <input [(ngModel)]="bvn" class="font-14 w-100 color-text fw-700" type="text" name="bvn" id="bvn" (input)="validateForm()" />
            </div>
          </div>
          <div class="col-6">
            <p class="color-header fw-700 mb-2 font-14">Referral Code</p>
            <div class="input-shape p-3">
              <input [(ngModel)]="referralCode" class="font-14 w-100 color-text fw-700" type="text" name="referralCode" id="referralCode" />
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <p class="fw-700 mb-2 font-14">Phone Number<span class="text-danger">*</span></p>
          <div class="input-shape d-flex align-items-center p-3">
            <input [(ngModel)]="phoneNumber" class="font-14 color-text fw-700 me-2 amount-input" type="tel" pattern="^[0-9,]*$" name="phoneNumber" id="phoneNumber" (input)="validateForm()" />
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="card-backg mb-4 p-3">
            <div class="d-flex mb-3 justify-content-between align-items-center">
              <img loading="lazy" style="width: 42px" src="/assets/images/tier-3.svg" alt="">
              <div>
                <button class="p-2 font-14 fw-700 border-0 btn-upgrade">Upgrade</button>
              </div>
            </div>
            <p class="font-16 mt-1 fw-700 mb-0">Tier 3</p>
            <p class="font-14 fw-400 mb-0">Upgrade your account now by completing your identity verification.</p>
          </div>
        </div>
        <div class="col-12 mt-4">
          <button type="submit" class="createBtn" [disabled]="!isFormValid()">Create Wallet</button>
        </div>
      </div>

      <!-- Success Message -->
      <div *ngIf="success && !showFundWalletFields">
        <div class="align-items-center justify-content-center text-center" style="margin-top: 8rem;">
          <div>
            <img src="assets/images/Illustration.png" alt="Create Wallet" class="modal-image">
            <h4>Wallet Created</h4>
            <p [innerHtml]="message"></p>
            <p [innerHtml]="successMessage"></p>
          </div>
          <div>
            <p class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700" (click)="toggleFundWalletFields()" style="margin-top: 3rem; width: 100%; cursor: pointer">Fund Wallet</p>
          </div>
        </div>
      </div>

      <!-- Fund Wallet Fields -->
      <div *ngIf="showFundWalletFields" class="row mt-4">
        <div class="col-12 mt-4">
          <p class="fw-700 mb-2 font-14">Amount</p>
          <div class="input-shape d-flex align-items-center p-3">
            <input class="font-14 color-text fw-700 me-2 amount-input" type="number" name="amount" id="amount" />
          </div>
        </div>
        <div class="col-12 mt-4">
          <p class="fw-700 mb-2 font-14">Purpose</p>
          <div class="input-shape d-flex align-items-center p-3">
            <input class="font-14 color-text fw-700 me-2 amount-input" type="text" name="purpose" id="purpose" />
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="mb-4 p-3 text-center " style="background-color: #F8F8FA;">
            <div class="">
              <p>Bank Name</p>
              <p class="fw-700 ">Wema Bank</p>
            </div>
            <div>
              <p>Bank Account Number</p>
              <p class="fw-700 " [innerHtml]="getNumbersFromMessage(successMessage)"></p>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <p class="col-12 p-3 color-white round-12 border-0 font-16 mt-4 text-center fw-700" style="width: 100%; background-color: #D7D1F5; cursor: pointer;">Submit</p>
        </div>
      </div>
    </form>
  </div>
</swap-modal>
