import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'go-back-action',
  templateUrl: './go-back-action.component.html',
  styleUrls: ['./go-back-action.component.scss'],
})
export class GoBackActionComponent {
  constructor(private location: Location) {}
  @Input() disableGoBack: boolean = false;
  @Input() text: string = 'Go Back';

  goBack() {
    if (!this.disableGoBack) this.location.back();
  }
}
