import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { CountryFilterPipe } from '../services/pipes/country-filter.pipe';
import { DateAsAgoPipe } from '../services/pipes/dateAsAgo';
import { PhoneFilterPipe } from '../services/pipes/phone-filter.pipe';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoBackActionComponent } from './components/go-back-action/go-back-action.component';
import { HeaderComponent } from './components/header/header.component';
import { LiveChatComponent } from './components/live-chat/live-chat.component';
import { MessagingFormComponent } from './components/messaging-form/messaging-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchComponent } from './components/search/search.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SwapInputComponent } from './components/swap-input/swap-input.component';
import { ActivitiesModalComponent } from './modals/activities-modal/activities-modal.component';
import { BuyerOfferComponent } from './modals/buyer-offer/buyer-offer.component';
import { CancelSubscriptionComponent } from './modals/cancel-subscription/cancel-subscription.component';
import { ChangeAvatarComponent } from './modals/change-avatar/change-avatar.component';
import { ChangePlanComponent } from './modals/change-plan/change-plan.component';
import { ChooseTimeComponent } from './modals/choose-time/choose-time.component';
import { CloseTransactionComponent } from './modals/close-transaction/close-transaction.component';
import { ConfirmChangePlanComponent } from './modals/confirm-change-plan/confirm-change-plan.component';
import { ConfirmTransferComponent } from './modals/confirm-transfer/confirm-transfer.component';
import { CreateOfferComponent } from './modals/create-offer/create-offer.component';
import { DeactivateModalComponent } from './modals/deactivate-modal/deactivate-modal.component';
import { DeactivateSocialsConfirmationComponent } from './modals/deactivate-socials-confirmation/deactivate-socials-confirmation.component';
import { DeactivatedUserModalComponent } from './modals/deactivated-user-modal/deactivated-user-modal.component';
import { EditCurrencyComponent } from './modals/edit-currency/edit-currency.component';
import { EditProfileModalComponent } from './modals/edit-profile-modal/edit-profile-modal.component';
import { EndTransactionComponent } from './modals/end-transaction/end-transaction.component';
import { ExtendTimeComponent } from './modals/extend-time/extend-time.component';
import { GetInTouchComponent } from './modals/get-in-touch/get-in-touch.component';
import { MoneyRecieveComponent } from './modals/money-recieve/money-recieve.component';
import { NewTicketComponent } from './modals/new-ticket/new-ticket.component';
import { OffersModalComponent } from './modals/offers-modal/offers-modal.component';
import { OrderHistoryComponent } from './modals/order-history/order-history.component';
import { OutOfTimeComponent } from './modals/out-of-time/out-of-time.component';
import { PaymentModalComponent } from './modals/payment-modal/payment-modal.component';
import { RatingReviewComponent } from './modals/rating-review/rating-review.component';
import { RemoveCardComponent } from './modals/remove-card/remove-card.component';
import { ReportTransactionComponent } from './modals/report-transaction/report-transaction.component';
import { SocialConnectComponent } from './modals/social-connect/social-connect.component';
import { StripePaymentComponent } from './modals/stripe-payment/stripe-payment.component';
import { TermsAndConditionsComponent } from './modals/terms-and-conditions/terms-and-conditions.component';
import { TokenHistoryComponent } from './modals/token-history/token-history.component';
import { TokenDetailsComponent } from './modals/token-details/token-details.component';
import { TopUpComponent } from './modals/top-up/top-up.component';
import { TransactionModalComponent } from './modals/transaction-modal/transaction-modal.component';
import { UpgradeAccountComponent } from './modals/upgrade-account/upgrade-account.component';
import { UploadProofComponent } from './modals/upload-proof/upload-proof.component';
import { VerifyPhoneNosComponent } from './modals/verify-phone-nos/verify-phone-nos.component';
import { VerifySocialComponent } from './modals/verify-social/verify-social.component';
import { ViewPaymentProofComponent } from './modals/view-payment-proof/view-payment-proof.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DisclaimerComponent } from './modals/disclaimer/disclaimer.component';
import { SearchFilterPipe } from '../services/pipes/search-filter.pipe';
import { CalendarModalComponent } from './components/calendar-modal/calendar-modal.component';
import { CreateWalletFormComponent } from './modals/create-wallet-form/create-wallet-form.component';
import { FundWalletComponent } from './modals/fund-wallet/fund-wallet.component';

// import { FbAuthButtonComponent } from './components/fb-auth-button/fb-auth-button.component';

const components: Array<any> = [
  FooterComponent,
  HeaderComponent,
  ActivitiesModalComponent,
  CreateOfferComponent,
  BuyerOfferComponent,
  ConfirmTransferComponent,
  EditCurrencyComponent,
  EmptyStateComponent,
  OutOfTimeComponent,
  ChooseTimeComponent,
  ExtendTimeComponent,
  EndTransactionComponent,
  UploadProofComponent,
  MoneyRecieveComponent,
  ViewPaymentProofComponent,
  RatingReviewComponent,
  CountryFilterPipe,
  DateAsAgoPipe,
  PhoneFilterPipe,
  SocialConnectComponent,
  ChangeAvatarComponent,
  SidebarComponent,
  NavbarComponent,
  ModalComponent,
  CurrencyInputComponent,
  SwapInputComponent,
  ButtonComponent,
  PhoneInputComponent,
  DeactivateModalComponent,
  OffersModalComponent,
  GoBackActionComponent,
  SearchBarComponent,
  TransactionModalComponent,
  MessagingFormComponent,
  ReportTransactionComponent,
  CloseTransactionComponent,
  NoDataComponent,
  SearchComponent,
  CardComponent,
  LiveChatComponent,
  PaginationComponent,
  EditProfileModalComponent,
  DeactivatedUserModalComponent,
  TermsAndConditionsComponent,
  VerifySocialComponent,
  VerifyPhoneNosComponent,
  UpgradeAccountComponent,
  NotFoundComponent,
  GetInTouchComponent,
  NewTicketComponent,
  TopUpComponent,
  TokenHistoryComponent,
  ChangePlanComponent,
  ConfirmChangePlanComponent,
  DeactivateSocialsConfirmationComponent,
  CancelSubscriptionComponent,
  TokenDetailsComponent,
  StripePaymentComponent,
  OrderHistoryComponent,
  RemoveCardComponent,
  PaymentModalComponent,
  DisclaimerComponent,
  SearchFilterPipe,
  CalendarModalComponent,
 FundWalletComponent,
  CreateWalletFormComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbTooltipModule,
    NgxEmojiPickerModule,
  ],
  exports: components,
  providers: [],
})
export class SharedModule {}
