<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      {{
        offer?.sellerUser?._id == globals.user?._id
          ? "View Offer"
          : "Make Offer"
      }}
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="buyerOfferForm.reset()"
      #closeModal
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form
      [formGroup]="buyerOfferForm"
      class="row g-3 justify-content-between"
      (ngSubmit)="onSubmit()"
    >
      <div class="col-6">
        <div class="color-header fw-700 mb-2 font-14">I have</div>
        <div
          class="dropdown-currency input-shape readOnly"
          id="currency-select-1"
        >
          <div class="dropdown-currency-input" id="dropdown-input-1">
            <img loading="lazy" [src]="iHave?.flag" alt="" />
            <input
              type="text"
              readonly
              placeholder="Select a currency"
              name="dropdown"
              [value]="iHave?.currency"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="color-header fw-700 mb-2 font-14">I want</div>
        <div
          class="dropdown-currency input-shape readOnly"
          id="currency-select-1"
        >
          <div class="dropdown-currency-input" id="dropdown-input-1">
            <img loading="lazy" [src]="iWant?.flag" alt="" />
            <input
              type="text"
              readonly
              placeholder="Select a currency"
              name="dropdown"
              [value]="iWant?.currency"
            />
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <p class="mb-0 badge">
          {{ "1 " + iWant?.currency }} =
          {{ offer?.rate + " " + iHave?.currency }}
        </p>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center gap-2 justify-content-between">
          <p class="color-header fw-700 mb-2 font-14">I want amount</p>
          <p class="font-12 fw-400 mb-2 color-grey600">
            {{
              offer?.minAmount
                | currency : offer?.localCurrency?.code : "symbol-narrow"
            }}
            -
            {{
              offer?.amount
                | currency : offer?.localCurrency?.code : "symbol-narrow"
            }}
          </p>
        </div>
        <div
          class="position-relative d-flex align-items-center gap-2 input-shape overflow-hidden"
        >
          <input
            class="font-14 w-100 fw-500 p-3 border-0 form-input"
            name="amount"
            id="amount"
            placeholder="0.00"
            type="text"
            [disabled]="offer?.sellerUser?._id == globals.user?._id"
            [value]="amount?.value"
            pattern="^[0-9,]*$"
            (input)="formatAmount($event, 'amount')"
          />
          <div
            class="d-flex align-items-center gap-2 position-absolute top-0"
            style="right: 1rem; height: 100%; padding: 0 10px"
          >
            <img loading="lazy" [src]="iWant?.flag" alt="" width="20" />
            <p class="mb-0 font-14 fw-500">{{ iWant?.currency }}</p>
          </div>
        </div>
        <div *ngIf="amount?.invalid && amount?.touched" class="text-danger">
          <div
            class="fw-500 font-12 mb-2"
            *ngIf="
              amount?.errors?.['required'] ||
             amount?.errors?.['exceedsMinAmount'] ||
              amount?.errors?.['exceedsMaxAmount']
            "
          >
            minimum :
            {{
              offer?.minAmount
                | currency : offer?.localCurrency?.code : "symbol-narrow"
            }}
            and maximum :
            {{
              offer?.amount
                | currency : offer?.localCurrency?.code : "symbol-narrow"
            }}
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <p class="mb-0 badge">
          <span class="text-muted">Sending</span>
          {{
            formatToNumber(amount?.value) * offer?.rate
              | currency : offer?.foreignCurrency?.code
          }}
        </p>
      </div>

      <div class="col-12">
        <p class="color-header fw-bold mb-2 font-16">Purpose of Transaction</p>
        <div
          class="input-shape p-3"
          [class.disabled]="offer?.sellerUser?._id === globals.user?._id"
        >
          <div
            class="transaction-dropdown"
            (click)="
              offer?.sellerUser?._id !== globals.user?._id &&
                startTransactionDropDown()
            "
          >
            <span>{{ buyerOfferForm.value.biddingPurpose || "Choose" }}</span>
            <div class="dropdown-option">
              <div
                class="dropdown-option-item"
                *ngFor="let purpose of transactionPurposeList"
                (click)="selectPurpose(purpose)"
              >
                {{ purpose }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 d-flex align-items-center justify-content-between gap-3"
      >
        <button
          type="submit"
          class="createBtn"
          [disabled]="buyerOfferForm.invalid"
          *ngIf="offer?.sellerUser?._id !== globals.user?._id"
        >
          Swap
        </button>
        <button
          type="button"
          class="createBtn archive"
          *ngIf="offer?.sellerUser?._id === globals.user?._id"
          (click)="archiveOffer(offer?._id)"
          [disabled]="offer?.transactionStatus === 'archived'"
          data-bs-dismiss="modal"
        >
          Archive
        </button>
        <button
          type="button"
          class="createBtn"
          data-bs-toggle="modal"
          attr.data-bs-target="{{ modalId2 }}"
          (click)="closeModal.click()"
          [disabled]="offer?.transactionStatus === 'archived'"
          *ngIf="offer?.sellerUser?._id === globals.user?._id"
        >
          Edit
        </button>
      </div>
      <div class="col-12 mt-3 detail-container">
        <div
          class="mb-2 d-flex align-items-center justify-content-between banner"
        >
          <div class="d-flex align-items-center gap-2">
            <img
              loading="lazy"
              [src]="offer?.sellerUser?.avatar"
              alt=""
              class="rounded-circle me-2"
              width="32"
              height="32"
            />
            <div>
              <p class="fw-500 color-grey900 font-14 mb-0 text-white">
                {{ offer?.sellerUser?.username }}
              </p>
              <div class="d-flex align-items-center">
                <ngb-rating
                  [rate]="
                    offer?.sellerUser?.metrics?.reviews?.averageRatings || 0
                  "
                  [max]="1"
                  [readonly]="true"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <i
                      class="bi-star{{
                        fill === 100 ? '-fill' : ''
                      }} font-14 color-yellow"
                      [class.filled]="fill === 100"
                      [class.low]="index < 3"
                    ></i>
                  </ng-template>
                </ngb-rating>

                <span class="color-yellow fw-400 font-14 ms-1 me-2">
                  {{ offer?.sellerUser?.metrics?.reviews?.averageRatings }}
                </span>
                <span class="font-14 fw-400 text-white"
                  >({{ offer?.sellerUser?.metrics?.reviews?.total }})</span
                >
              </div>
            </div>
          </div>
          <span class="fw-400 text-white"
            >{{ offer?.sellerUser?.metrics?.reviews?.total }} Trades</span
          >
        </div>
        <div class="row g-0 gy-3 mb-3 mt-0 dashes pb-3">
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Online</p>
              <p class="mb-0 font-12 text-muted">
                {{
                  offer?.sellerUser?.online?.status === true
                    ? "Online"
                    : "Offline"
                }}
              </p>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center gap-2">
            <span class="dot"></span>
            <div>
              <p class="mb-0 font-14 fw-bolder">Response Time</p>
              <p class="mb-0 font-12 text-muted">
                {{ offer?.sellerUser?.metrics?.biddings?.averageResponseTime }}
              </p>
            </div>
          </div>
        </div>
        <p class="mb-2 font-14 fw-bolder">Offer Details</p>
        <div class="row g-0 gy-3">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between mt-3 mb-3 gap-3"
            >
              <p class="mb-0 font-14 text-muted">Offer ID</p>
              <p class="mb-0 font-14 fw-bolder">#{{ offer?._id?.slice(-8) }}</p>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-3 gap-3"
            >
              <p class="mb-0 font-14 text-muted">Date</p>
              <p class="mb-0 font-14 fw-bolder">
                {{ globals.formatDateAndTimestamp(offer?.createdAt) }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-3 gap-3"
            >
              <p class="mb-0 font-14 text-muted">Amount</p>
              <p class="mb-0 font-14 fw-bolder">
                {{ offer?.amount | currency : offer?.localCurrency?.code }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-3 gap-3"
            >
              <p class="mb-0 font-14 text-muted">Rate</p>
              <p class="mb-0 font-14 fw-bolder">
                {{ "1 " + iWant?.currency }} =
                {{ offer?.rate + " " + iHave?.currency }}
              </p>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between gap-4"
            >
              <p class="mb-0 font-14 text-muted">Wants</p>
              <p class="mb-0 font-14 fw-bolder">
                <img
                  loading="lazy"
                  [src]="
                    globals.searchCountryBy(
                      offer?.foreignCurrency?.country,
                      'name'
                    )?.flag
                  "
                  alt=""
                  class="me-1"
                  style="width: 15px; height: 15px; object-fit: contain"
                />
                {{ offer?.foreignCurrency?.country }}
                <span class="text-muted">{{
                  offer?.foreignCurrency?.code
                }}</span>
              </p>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between gap-4"
            >
              <p class="mb-0 font-14 text-muted">Sending from</p>
              <p class="mb-0 font-14 fw-bolder">
                {{ offer?.paymentSendFrom }}
              </p>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between gap-4"
            >
              <p class="mb-0 font-14 text-muted">Receiving with</p>
              <p class="mb-0 font-14 fw-bolder">
                {{ offer?.paymentReceiveThrough }}
              </p>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between gap-4"
            >
              <p class="mb-0 font-14 text-muted">Limit</p>
              <p class="mb-0 font-14 fw-bolder">
                {{
                  offer?.minAmount
                    | currency : offer?.localCurrency?.code : "symbol-narrow"
                }}
                -
                {{
                  offer?.amount
                    | currency : offer?.localCurrency?.code : "symbol-narrow"
                }}
              </p>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-between gap-4">
              <p class="mb-0 font-14 text-muted" style="white-space: nowrap">
                Preferred Country
              </p>
              <p class="mb-0 font-14 fw-bolder">
                <ng-container
                  *ngFor="
                    let country of offer?.preferredCountries;
                    let i = index
                  "
                >
                  <img
                    loading="lazy"
                    [src]="
                      globals.searchCountryBy(country?.country, 'name')?.flag
                    "
                    alt=""
                    class="me-1"
                    style="width: 15px; height: 15px; object-fit: contain"
                  />
                  {{ country.country }}
                  {{ i < offer?.preferredCountries?.length - 1 ? "," : "" }}
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</swap-modal>

<ng-template #sendFrom>
  <div class="text-left font-12 p-2">
    <div class="color-pink fw-700 mb-2">Send From</div>
    <div class="color-text fw-400">
      Choose this payment method for how you would like to send your “I have
      currency”.
    </div>
  </div>
</ng-template>

<ng-template #receiveThrough>
  <div class="text-left font-12 p-2">
    <div class="color-pink fw-700 mb-2">Receive Through</div>
    <div class="color-text fw-400">
      Choose this method for how you would prefer to receive your “I want
      currency”.
    </div>
  </div>
</ng-template>
