import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CreateWalletResponse,
  Offer,
  Plan,
  Ratings_Reviews,
  Token,
  TokenBundle,
  Transaction,
  VerifyAccountResponse,
  Wallet,
} from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

const routes = {
  allOffers: 'offers',
  createOffer: 'offers',
  buyOffer: 'biddings/',
  filterTransaction: 'swap/sort-transactions',
  getTopTransactions: 'offers/free',
  getAllTransactions: 'biddings/mine/',
  approveTransaction: 'biddings/',
  createReview: 'ratings-reviews',
  getPendingReviews: 'ratings-reviews/pending',
  getMyReviews: 'ratings-reviews',
  getAllMyOffers: 'offers/mine/',
  getPublicOffers: 'offers/public/',
  getToken: 'tokens/average',
  getTokenHistory: 'tokens/history',
  createTokenPaymentSession: 'payments/token-payment-session',
  createPlanPaymentSession: 'payments/plan-payment-session',
  cancelSubscription: 'tokens/cancel-subscription',
  cancelBidding: 'biddings/cancel/',
  getBidding: 'biddings/',
  getOfferBiddings: 'biddings/offer/',
  consumeToken: 'tokens/consume',
  getOffersCount: 'offers/count',
  acceptDisclaimer: 'biddings/disclaimer-approval',
  getPlans: 'plans/all',
  addAccountDetails: 'profile/bank-details',
  getTokenBundles: 'token-bundles/all',
  allWalletById: 'wallets/balance',
  verifyAccount: 'wallets/validate-bank-account',
  createWallet: 'wallets/create',
};

// https://refapi.expatswap.com/api/offers/free

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  offers: Offer[] = [];
  offer: Offer | Transaction | any;
  tokenBundles: TokenBundle[] = [];
  plans: Plan[] = [];
  transactions: Transaction[] = [];
  activities: Transaction[] = [];
  transaction: Transaction = {} as Transaction;
  profileTransactions: Transaction[] = [];
  profileTransaction: Offer = {} as Offer;
  activity: Transaction | any;
  ratings_reviews: Ratings_Reviews[] = [];
  tokens: Token[] = [];
  wallet: any;
  tokensPagination = {
    earned: 0,
    spent: 0,
    purchased: 0,
    period: 'daily',
  };
  ticketsPagination: any = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    myTicketsTotal: 0,
    page: 1,
    search: '',
    category: '',
    ticketStatus: '',
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
  };
  tokenHistoryPagination = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    page: 1,
    mode: 'spent',
    startDate: '',
    endDate: '',
  };

  offersPagination: any = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    page: 1,
    dateTo: '',
    dateFrom: '',
    minRate: '',
    maxRate: '',
    paymentMethod: '',
    haveCurrency: '',
    wantCurrency: '',
    haveAmount: '',
    wantAmount: '',
    myRate: '',
    country: '',
    ratings: 0,
    online: '',
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
  };

  transactionsPagination: any = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    countPending: 0,
    countOngoing: 0,
    countCompleted: 0,
    countFailed: 0,
    countDeclined: 0,
    countCancelled: 0,
    countArchived: 0,
    countAll: 0,
    page: 1,
    sort: {
      field: 'createdAt',
      order: 'desc',
      status: 'ongoing',
      search: '',
    },
  };

  activitiesPagination = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    page: 1,
    sort: {
      field: 'createdAt',
      order: 'desc',
      status: 'all',
    },
  };

  profileOffersPagination = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    page: 1,
    sort: {
      status: 'pending',
    },
  };

  ratingsReviewPagination = {
    itemsPerPage: 10,
    totalItemsCount: 0,
    page: 1,
  };

  modeOfTransaction: string = 'active';

  constructor(
    private api: RequestService,
    private globals: GlobalsService,
    private http: HttpClient
  ) {}

  // Function to get  details
  async getTopTransactionList(currency: string = '') {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.getTopTransactions}?currency=${currency}`
        );
        this.offers = resp.data as Offer[];
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to get all transaction offers
  async getAllOffers() {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.offersPagination).forEach((key) => {
          if (key === 'sort') {
            params += `sortBy=${this.offersPagination[key].field}&order=${this.offersPagination[key].order}&`;
          } else if (this.offersPagination[key] !== ('' || 0)) {
            params += `${key}=${this.offersPagination[key]}&`;
          }
        });

        const resp: any = await this.api.get(`${routes.allOffers}?${params}`);
        if (resp.message.toLowerCase() === 'no offers found') {
          this.globals.spinner.hide();
          this.globals.toast.error(resp.message);
          reject(resp);
        }
        this.offers = resp.data.offers as Offer[];
        this.offersPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        if (ex === 'Pending ratings review') {
          this.globals.spinner.hide();
          this.globals.toast.warn(ex);
        } else {
          this.globals.spinner.hide();
          this.globals.toast.error(ex || 'Internal server error');
        }
        reject(ex);
      }
    });
  }

  // Function to get a single offer
  async getOffer(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.allOffers + '/' + id);
        this.offers = resp.data as Offer[];
        this.offersPagination.totalItemsCount = 1;
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // async getOfferById(offerId: string) {
  //   return await new Promise(async (resolve, reject) => {
  //     try {
  //       const resp: any = await this.api.get(routes.allOffersById + '/' + offerId);
  //       this.offers = resp.data as Offer[];
  //       this.offersPagination.totalItemsCount = 1;
  //       resolve(resp.data);
  //     } catch (ex: any) {
  //       this.globals.spinner.hide();
  //       this.globals.toast.error(ex);
  //       reject(ex);
  //     }
  //   });
  // }

  async getOffers(offerId: string) {
    let params = `offerId=${offerId}`;
    Object.keys(this.ticketsPagination).forEach((key) => {
      if (key === 'sort') {
        params += `&sort=${this.ticketsPagination.sort.field}&order=${this.ticketsPagination.sort.order}`;
      } else {
        params += `&${key}=${this.ticketsPagination[key]}`;
      }
    });

    // const resp: any = await this.api.get(`${routes.allOffers} + ?${params}`);
    const resp: any = await this.api.get(routes.allOffers + '/' + params);
    this.offer = resp.data;
    this.ticketsPagination.totalItemsCount = resp.data.totalItemsCount;
    // await this.getHelpHistoryCount();
    return true;
  }
  catch(ex: any) {
    console.error('Error fetching user tickets:', ex);
    throw ex;
  }

  // Function to update an offer
  async updateOffer(offer: Offer, _id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.patch(
          `${routes.createOffer}/${_id}`,
          offer
        );
        this.globals.toast.success(resp.message);
        this.globals.mixpanel.updateOfferEvent(offer, 'success');
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        this.globals.mixpanel.updateOfferEvent(offer, 'failed');
        reject(ex);
      }
    });
  }

  // Function to create a new offer
  async createOffer(offer: Offer) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(routes.createOffer, offer);
        this.globals.toast.success(resp.message);
        this.globals.mixpanel.createOfferEvent(offer, 'success');
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        this.globals.mixpanel.createOfferEvent(offer, 'failed');
        reject(ex);
      }
    });
  }

  // Function to buy offer
  async buyOffer(offer: Offer, id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(routes.buyOffer + id, offer);
        this.globals.toast.success(resp.message);
        offer._id = id;
        this.globals.mixpanel.buyOfferEvent(offer, 'success');
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        offer._id = id;
        this.globals.mixpanel.buyOfferEvent(offer, 'failed');
        reject(ex);
      }
    });
  }

  // This function is used to get all activities between the current user and the other user
  async getAllActivities(
    filter: string = this.activitiesPagination.sort.status,
    page: number = this.activitiesPagination.page,
    itemsPerPage: number = this.activitiesPagination.itemsPerPage
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.activities = [];
        const resp: any = await this.api.get(
          `${routes.getAllTransactions}inactive-${filter}?page=${page}&itemsPerPage=${itemsPerPage}`
        );
        this.activities = resp.data.biddings as Transaction[];
        this.activitiesPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to get all transactions between the current user and the other user
  async getAllTransactions(
    mode: string = 'active',
    filter: string = this.transactionsPagination.sort.status,
    page: number = this.transactionsPagination.page,
    itemsPerPage: number = this.transactionsPagination.itemsPerPage
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.getAllTransactions}${mode}-${filter}?page=${page}&itemsPerPage=${itemsPerPage}`
        );
        this.transactions = resp.data.biddings as Transaction[];
        this.transactionsPagination.totalItemsCount = resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        if (ex === 'Pending ratings review') {
          this.globals.spinner.hide();
          this.globals.toast.warn(ex);
        } else {
          this.globals.spinner.hide();
          this.globals.toast.error(ex || 'Internal server error');
        }
        reject(ex);
      }
    });
  }
  // Function to get a single offer
  async getTransactionById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.getBidding + '/' + id);
        resp.data.bidding.buyer.user = resp.data.bidding.buyer.userId;
        resp.data.bidding.seller.user = resp.data.bidding.seller.userId;
        resp.data.bidding.buyer.userId = resp.data.bidding.buyer.userId._id;
        resp.data.bidding.seller.userId = resp.data.bidding.seller.userId._id;
        this.transaction = resp.data as Transaction;
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // Function to get transactions of an offer
  async getTransactionsByOfferId(
    offerId: string,
    filter: string = this.transactionsPagination.sort.status
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        let params = '';
        Object.keys(this.transactionsPagination).forEach((key) => {
          if (key === 'sort') {
            params += `sortBy=${this.transactionsPagination[key].field}&order=${this.transactionsPagination[key].order}&status=${filter}&`;
          } else if (this.transactionsPagination[key] !== ('' || 0)) {
            params += `${key}=${this.transactionsPagination[key]}&`;
          }
        });
        const resp: any = await this.api.get(
          `${routes.getOfferBiddings + offerId}?${params}`
        );
        console.log('okay', resp);
        this.transactions = resp.data.biddings as Transaction[];
        this.transactionsPagination.totalItemsCount = resp.data.totalCount;
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  async getUserHelpHistory(offerId: string) {
    try {
      let params = `offerId=${offerId}`;
      Object.keys(this.ticketsPagination).forEach((key) => {
        if (key === 'sort') {
          params += `&sort=${this.ticketsPagination.sort.field}&order=${this.ticketsPagination.sort.order}`;
        } else {
          params += `&${key}=${this.ticketsPagination[key]}`;
        }
      });

      // const resp: any = await this.api.get(`biddings/offer?${params}`);
      const resp: any = await this.api.get(
        `${routes.getOfferBiddings + offerId}?${params}`
      );
      console.log('okay', resp);
      // this.tickets = resp.data.tickets;
      // this.ticketsPagination.totalItemsCount = resp.data.totalItemsCount;
      // await this.getHelpHistoryCount();
      return true;
    } catch (ex) {
      console.error('Error fetching user tickets:', ex);
      throw ex;
    }
  }

  // This function approves a transaction
  async approveTransaction(
    approval: boolean,
    tranx_id: string,
    biddingId: string
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.update(
          `${routes.approveTransaction}${tranx_id}`,
          {
            biddingId,
            approval,
          }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        this.globals.mixpanel.approveOfferEvent(tranx_id, 'failed');
        if (
          ex.includes('verification status') &&
          this.globals.user?.verificationStatus?.rank !== 3
        )
          this.globals.openModal('upgradeModal');
        reject(ex);
      }
    });
  }

  // This function cancels a transaction
  async cancelTransaction(biddingId: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(
          `${routes.cancelBidding}${biddingId}`,
          {}
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  async getGlobalRate(want: string, have: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.http
          .get(`${environment.currencyApiBaseURL}${have}/${want}`)
          .toPromise();
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        // this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // This function is used to create a review
  async createReview(review: Ratings_Reviews) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(routes.createReview, review);
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        console.log(ex);
        reject(ex);
      }
    });
  }

  // This function is used to get all pending reviews
  async getAllPendingReviews() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.getPendingReviews);
        this.globals.ratingReview = resp.data;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // This function is used to get all my reviews
  async getAllMyReviews(
    id?: string,
    page: number = this.ratingsReviewPagination.page,
    itemsPerPage: number = this.ratingsReviewPagination.itemsPerPage
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.getMyReviews}${
            id ? '/' + id : ''
          }?page=${page}&itemsPerPage=${itemsPerPage}`
        );
        this.ratings_reviews = resp.data.ratingsReviews as Ratings_Reviews[];
        this.ratingsReviewPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // This function is used to get all my offers
  async getAllMyOffers(
    filter: string = this.profileOffersPagination.sort.status,
    page: number = this.profileOffersPagination.page,
    itemsPerPage: number = this.profileOffersPagination.itemsPerPage
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.getAllMyOffers}${filter}?page=${page}&itemsPerPage=${itemsPerPage}`
        );
        this.profileTransactions = resp.data.offers as Transaction[];
        this.profileOffersPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex);
        reject(ex);
      }
    });
  }

  // This function is used to get public profile offers
  async getPublicOffers(
    id: string,
    page: number = this.profileOffersPagination.page,
    itemsPerPage: number = this.profileOffersPagination.itemsPerPage
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(
          `${routes.getPublicOffers}${id}?page=${page}&itemsPerPage=${itemsPerPage}`
        );
        this.profileTransactions = resp.data.offers as Transaction[];
        this.profileOffersPagination.totalItemsCount =
          resp.data.totalItemsCount;
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to edit an offer
  async editOffer(offerId: string, archive: boolean = false, rate?: number) {
    return await new Promise(async (resolve, reject) => {
      try {
        let data: any = {};
        if (archive) data['transactionStatus'] = 'archived';
        if (rate) data['rate'] = rate;
        const resp: any = await this.api.patch(
          `${routes.createOffer}/${offerId}`,
          data
        );
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async getTokens(period: string = this.tokensPagination.period) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `${routes.getToken}?period=${period}`
        );
        this.tokensPagination.earned = resp.data.earned;
        this.tokensPagination.spent = resp.data.spent;
        this.tokensPagination.purchased = resp.data.purchased;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async getTokenHistory(mode: string = this.tokenHistoryPagination.mode) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.tokens = [];
        this.globals.spinner.show();
        const resp: any = await this.api.get(
          `${routes.getTokenHistory}?type=${mode}&startDate=${this.tokenHistoryPagination.startDate}&endDate=${this.tokenHistoryPagination.endDate}&page=${this.tokenHistoryPagination.page}&itemsPerPage=${this.tokenHistoryPagination.itemsPerPage}`
        );
        this.tokens = resp.data.tokens as Token[];
        this.globals.spinner.hide();
        resolve(resp);
      } catch (ex: any) {
        reject(ex);
      }
    });
  }

  async createTokenPaymentSession(tokenBundle: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(
          routes.createTokenPaymentSession,
          { tokenBundle }
        );
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async createPlanPaymentSession(plan: string | undefined, period: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.createPlanPaymentSession, {
          subscription: plan,
          period,
        });
        this.globals.spinner.hide();
        resolve(resp.data);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async cancelSubscription(cancellationReason: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.cancelSubscription, {
          period: 'cancel',
          cancellationReason,
        });
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async consumeToken(type: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.post(routes.consumeToken, {
          trigger: type,
        });
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async getOffersCount() {
    return await new Promise(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.getOffersCount);
        this.transactionsPagination = {
          ...this.transactionsPagination,
          ...resp.data,
        };
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async getPlans(): Promise<Plan[]> {
    return new Promise<Plan[]>(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.getPlans);
        this.plans = resp.data as Plan[];
        resolve(this.plans);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  async getTokenBundles(): Promise<TokenBundle[]> {
    return new Promise<TokenBundle[]>(async (resolve, reject) => {
      try {
        const resp: any = await this.api.get(routes.getTokenBundles);
        this.tokenBundles = resp.data as TokenBundle[];
        resolve(this.tokenBundles);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex.message || 'Internal server error');
        reject(ex);
      }
    });
  }

  // This function is used to send accept disclaimer message
  async sendAcceptDisclaimer(bidding_id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(
          `${routes.acceptDisclaimer}/${bidding_id}`,
          {
            disclaimerAccepted: true,
          }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  async saveAccountDetails(
    userId: string,
    accountDetailsArray: {
      account_number: string;
      account_name: string;
      bank_code: string;
      Bank_name: string;
    }[]
  ) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.patch(
          `${routes.addAccountDetails}/${userId}`,
          { bankDetails: accountDetailsArray }
        );
        this.globals.spinner.hide();
        this.globals.toast.success(resp.message);
        resolve(resp);
      } catch (ex: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(ex || 'Internal server error');
        reject(ex);
      }
    });
  }

  // async createWallet(userId: string, walletDetails: { phoneNumber: string, email: string, firstName: string, lastName: string,referralCode:string,bvn:string }) {
  //   return await new Promise(async (resolve, reject) => {
  //     try {
  //       this.globals.spinner.show();
  //       const resp = await this.api.post(
  //         `${routes.createWallet}/${userId}`,
  //         walletDetails
  //       ) as CreateWalletResponse;

  //       this.globals.spinner.hide();
  //       this.globals.toast.success(resp.message);
  //       resolve(resp);
  //     } catch (ex: any) {
  //       this.globals.spinner.hide();
  //       this.globals.toast.error(ex || 'Internal server error');
  //       reject(ex);
  //     }
  //   });
  // }

  // async createWallet(userId: string, walletDetails: { phoneNumber: string, email: string, firstName: string, lastName: string, referralCode: string, bvn: string }): Promise<CreateWalletResponse> {
  //   try {
  //     this.globals.spinner.show();
  //     const resp: CreateWalletResponse = await this.api.post(
  //               `${routes.createWallet}/${userId}`,
  //               {  walletDetails }
  //             );
  //     this.globals.spinner.hide();
  //     this.globals.toast.success(resp.message);
  //     return resp;
  //   } catch (ex: any) {
  //     this.globals.spinner.hide();
  //     this.globals.toast.error(ex.message || 'Internal server error');
  //     throw ex; // Ensure you throw the error to handle it in onSubmit
  //   }
  // }

  async createWallet(
    userId: string,
    walletDetails: {
      phoneNumber: string;
      email: string;
      firstName: string;
      lastName: string;
      referralCode: string;
      bvn: string;
    }
  ): Promise<CreateWalletResponse> {
    try {
      this.globals.spinner.show();

      const resp: any = await this.api.post(
        `${routes.createWallet}/${userId}`,
        { walletDetails }
      );

      if (resp && resp.message) {
        // this.globals.toast.success(resp.message); // Show success message
        return resp as CreateWalletResponse; // Resolve with proper type
      } else {
        console.error('Invalid response structure:', resp);
        this.globals.toast.error('Invalid response structure'); // Show error message
        throw new Error('Invalid response structure'); // Throw error
      }
    } catch (ex: any) {
      console.error('API call failed:', ex);
      // this.globals.toast.success(resp.message);
      // this.globals.toast.error(error); // Show error message
      throw ex; // Rethrow to handle it where createWallet is called
    } finally {
      this.globals.spinner.hide(); // Ensure spinner is hidden
    }
  }

  verifyAccount(
    account_number: string,
    bank_code: string
  ): Promise<VerifyAccountResponse> {
    return new Promise<VerifyAccountResponse>(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post(routes.verifyAccount, {
          account_number,
          bank_code,
        });
        if (resp && resp.account_name) {
          resolve(resp as VerifyAccountResponse);
        } else {
          console.error('Invalid response structure:', resp);
          reject(new Error('Invalid response structure'));
        }
      } catch (ex: any) {
        console.error('API call failed:', ex);
        reject(ex);
      } finally {
        this.globals.spinner.hide();
      }
    });
  }

  resetParams() {
    this.offersPagination = {
      itemsPerPage: 10,
      totalItemsCount: 0,
      page: 1,
      minRate: '',
      maxRate: '',
      dateTo: '',
      dateFrom: '',
      paymentMethod: '',
      haveCurrency: '',
      wantCurrency: '',
      haveAmount: '',
      wantAmount: '',
      myRate: '',
      country: '',
      ratings: 0,
      online: '',
      sort: {
        field: 'createdAt',
        order: 'desc',
      },
    };

    this.transactionsPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      countPending: 0,
      countOngoing: 0,
      countCompleted: 0,
      countFailed: 0,
      countDeclined: 0,
      countCancelled: 0,
      countArchived: 0,
      countAll: 0,
      page: 1,
      sort: {
        field: 'createdAt',
        order: 'desc',
        status: 'ongoing',
        search: '',
      },
    };

    this.activitiesPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      sort: {
        field: 'createdAt',
        order: 'desc',
        status: 'all',
      },
    };

    this.profileOffersPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      sort: {
        status: 'pending',
      },
    };

    this.ratingsReviewPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
    };

    this.tokensPagination = {
      earned: 0,
      spent: 0,
      purchased: 0,
      period: 'daily',
    };

    this.tokenHistoryPagination = {
      itemsPerPage: 5,
      totalItemsCount: 0,
      page: 1,
      mode: 'earned',
      startDate: '',
      endDate: '',
    };

    this.offers = [];
    this.transactions = [];
    this.activities = [];
    this.profileTransactions = [];
  }
}
