import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'upload-proof',
  templateUrl: './upload-proof.component.html',
  styleUrls: ['./upload-proof.component.scss'],
})
export class UploadProofComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitProofUploaded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeBtn') closeBtn: any;

  file: any;
  fileName: string = '';
  fileSize: string = '';
  progress: number = 0;
  setInterval: any;
  fileOverZone: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  uploadFile(event: any) {
    this.file = event.target.files[0];
    this.setInterval = setInterval(() => {
      this.progress += 5;
      if (this.progress === 100) clearInterval(this.setInterval);
    }, 100);

    this.fileName = this.file.name;
    this.fileSize = (this.file.size / 1024).toFixed(2) + ' KB';
  }

  uploadProof() {
    let formData = new FormData();
    formData.append('proof', this.file);
    this.emitProofUploaded.emit(formData);
    this.clearFile();
  }

  clearFile() {
    this.file = null;
    this.fileName = '';
    this.fileSize = '';
    this.progress = 0;
    clearInterval(this.setInterval);
  }

  onDragEnter() {
    this.fileOverZone = true;
  }

  onDragLeave() {
    this.fileOverZone = false;
  }
}
