export const environment = {
  production: true,
  apiBaseURL: 'https://api.expatswap.com/api/',
  // apiBaseURL: 'http://localhost:3000/api/',
  googleClientId:
    '482016136889-de4lkf8c5fv7jnv4i31a7ovcfsnku2gi.apps.googleusercontent.com',
  verificationURL: 'https://verify.expatswap.com',
  facebookClientId: '2143845802454004',
  firebaseConfig: {
    apiKey: 'AIzaSyAW23AVhEQDk-T5TsfhaIfHaVtHFhy6MBc',
    authDomain: 'expatswap-29a49.firebaseapp.com',
    databaseURL: 'https://expatswap-29a49-default-rtdb.firebaseio.com',
    projectId: 'expatswap-29a49',
    storageBucket: 'expatswap-29a49.appspot.com',
    messagingSenderId: '482016136889',
    appId: '1:482016136889:web:7f5750ef240987a90b1568',
    measurementId: 'G-ZCEGNV9VJ5',
  },
  currencyApiBaseURL:
    'https://v6.exchangerate-api.com/v6/338e71a5109797888911d2cb/pair/',
  socketConfig: {
    // url: 'http://localhost:5000/',
    url: 'https://bkoffapi.expatswap.com/',
  },
  stripeSecretKey:
    'sk_test_51NrzXMGU562yOts6FdyxoyMi3sSV987jLWrMfrGUWrs9WoGUqIOtqxltQFtutqYBN01gOr9pK5B3uVPCc3bV2mBq00QgyL0SW1',
  mixpanel: {
    token: '06da36b5e4f24861b8008b6f6ef5be08',
    debug: false,
  },
};
